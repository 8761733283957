.lst-kix_3d3nmrtok4kr-0 > li {
  counter-increment: lst-ctn-kix_3d3nmrtok4kr-0;
}

ol.lst-kix_fbk3h0ckjtov-6.start {
  counter-reset: lst-ctn-kix_fbk3h0ckjtov-6 0;
}

.lst-kix_i0e5828rhinh-1 > li {
  counter-increment: lst-ctn-kix_i0e5828rhinh-1;
}

.lst-kix_ckip1ox7jmig-4 > li {
  counter-increment: lst-ctn-kix_ckip1ox7jmig-4;
}

.lst-kix_kp4y6vngjbdi-6 > li {
  counter-increment: lst-ctn-kix_kp4y6vngjbdi-6;
}

ol.lst-kix_xzyemwvei0e4-0.start {
  counter-reset: lst-ctn-kix_xzyemwvei0e4-0 0;
}

.lst-kix_3d3nmrtok4kr-5 > li:before {
  content: "" counter(lst-ctn-kix_3d3nmrtok4kr-5, lower-roman) ". ";
}

.lst-kix_3d3nmrtok4kr-6 > li:before {
  content: "" counter(lst-ctn-kix_3d3nmrtok4kr-6, decimal) ". ";
}

ol.lst-kix_ckip1ox7jmig-1.start {
  counter-reset: lst-ctn-kix_ckip1ox7jmig-1 0;
}

.lst-kix_fbk3h0ckjtov-8 > li {
  counter-increment: lst-ctn-kix_fbk3h0ckjtov-8;
}

.lst-kix_3d3nmrtok4kr-7 > li:before {
  content: "" counter(lst-ctn-kix_3d3nmrtok4kr-7, lower-latin) ". ";
}

ol.lst-kix_2hr0ax18du19-8.start {
  counter-reset: lst-ctn-kix_2hr0ax18du19-8 0;
}

ol.lst-kix_y75o93thcz3c-3.start {
  counter-reset: lst-ctn-kix_y75o93thcz3c-3 0;
}

.lst-kix_3d3nmrtok4kr-8 > li:before {
  content: "" counter(lst-ctn-kix_3d3nmrtok4kr-8, lower-roman) ". ";
}

ol.lst-kix_fbk3h0ckjtov-0.start {
  counter-reset: lst-ctn-kix_fbk3h0ckjtov-0 0;
}

ol.lst-kix_3d3nmrtok4kr-5.start {
  counter-reset: lst-ctn-kix_3d3nmrtok4kr-5 0;
}

.lst-kix_6uto0bibcuqj-0 > li {
  counter-increment: lst-ctn-kix_6uto0bibcuqj-0;
}

.lst-kix_xzyemwvei0e4-6 > li {
  counter-increment: lst-ctn-kix_xzyemwvei0e4-6;
}

.lst-kix_i0e5828rhinh-3 > li {
  counter-increment: lst-ctn-kix_i0e5828rhinh-3;
}

ol.lst-kix_kp4y6vngjbdi-3.start {
  counter-reset: lst-ctn-kix_kp4y6vngjbdi-3 0;
}

.lst-kix_kp4y6vngjbdi-8 > li {
  counter-increment: lst-ctn-kix_kp4y6vngjbdi-8;
}

ol.lst-kix_6uto0bibcuqj-3.start {
  counter-reset: lst-ctn-kix_6uto0bibcuqj-3 0;
}

ol.lst-kix_i0e5828rhinh-3.start {
  counter-reset: lst-ctn-kix_i0e5828rhinh-3 0;
}

.lst-kix_3d3nmrtok4kr-2 > li {
  counter-increment: lst-ctn-kix_3d3nmrtok4kr-2;
}

.lst-kix_ckip1ox7jmig-2 > li {
  counter-increment: lst-ctn-kix_ckip1ox7jmig-2;
}

.lst-kix_fbk3h0ckjtov-6 > li {
  counter-increment: lst-ctn-kix_fbk3h0ckjtov-6;
}

ol.lst-kix_ckip1ox7jmig-1 {
  list-style-type: none;
}

ol.lst-kix_ckip1ox7jmig-0 {
  list-style-type: none;
}

ol.lst-kix_ckip1ox7jmig-5 {
  list-style-type: none;
}

ol.lst-kix_ckip1ox7jmig-4 {
  list-style-type: none;
}

ol.lst-kix_ckip1ox7jmig-3 {
  list-style-type: none;
}

ol.lst-kix_xzyemwvei0e4-1.start {
  counter-reset: lst-ctn-kix_xzyemwvei0e4-1 0;
}

ol.lst-kix_ckip1ox7jmig-2 {
  list-style-type: none;
}

.lst-kix_y75o93thcz3c-7 > li {
  counter-increment: lst-ctn-kix_y75o93thcz3c-7;
}

ol.lst-kix_3d3nmrtok4kr-4.start {
  counter-reset: lst-ctn-kix_3d3nmrtok4kr-4 0;
}

ol.lst-kix_ckip1ox7jmig-8 {
  list-style-type: none;
}

ol.lst-kix_ckip1ox7jmig-7 {
  list-style-type: none;
}

ol.lst-kix_ckip1ox7jmig-6 {
  list-style-type: none;
}

ol.lst-kix_3d3nmrtok4kr-0 {
  list-style-type: none;
}

ol.lst-kix_i0e5828rhinh-4.start {
  counter-reset: lst-ctn-kix_i0e5828rhinh-4 0;
}

ol.lst-kix_3d3nmrtok4kr-2 {
  list-style-type: none;
}

ol.lst-kix_3d3nmrtok4kr-1 {
  list-style-type: none;
}

.lst-kix_6uto0bibcuqj-7 > li {
  counter-increment: lst-ctn-kix_6uto0bibcuqj-7;
}

ol.lst-kix_2hr0ax18du19-2 {
  list-style-type: none;
}

ol.lst-kix_2hr0ax18du19-2.start {
  counter-reset: lst-ctn-kix_2hr0ax18du19-2 0;
}

ol.lst-kix_2hr0ax18du19-3 {
  list-style-type: none;
}

ol.lst-kix_2hr0ax18du19-0 {
  list-style-type: none;
}

.lst-kix_xzyemwvei0e4-8 > li {
  counter-increment: lst-ctn-kix_xzyemwvei0e4-8;
}

ol.lst-kix_2hr0ax18du19-1 {
  list-style-type: none;
}

ol.lst-kix_3d3nmrtok4kr-8 {
  list-style-type: none;
}

ol.lst-kix_2hr0ax18du19-6 {
  list-style-type: none;
}

ol.lst-kix_3d3nmrtok4kr-7 {
  list-style-type: none;
}

ol.lst-kix_2hr0ax18du19-7 {
  list-style-type: none;
}

ol.lst-kix_2hr0ax18du19-4 {
  list-style-type: none;
}

ol.lst-kix_2hr0ax18du19-5 {
  list-style-type: none;
}

ol.lst-kix_3d3nmrtok4kr-4 {
  list-style-type: none;
}

.lst-kix_xzyemwvei0e4-2 > li {
  counter-increment: lst-ctn-kix_xzyemwvei0e4-2;
}

ol.lst-kix_3d3nmrtok4kr-3 {
  list-style-type: none;
}

ol.lst-kix_3d3nmrtok4kr-6 {
  list-style-type: none;
}

ol.lst-kix_2hr0ax18du19-8 {
  list-style-type: none;
}

ol.lst-kix_3d3nmrtok4kr-5 {
  list-style-type: none;
}

ol.lst-kix_fbk3h0ckjtov-5.start {
  counter-reset: lst-ctn-kix_fbk3h0ckjtov-5 0;
}

ol.lst-kix_y75o93thcz3c-8.start {
  counter-reset: lst-ctn-kix_y75o93thcz3c-8 0;
}

.lst-kix_2hr0ax18du19-0 > li {
  counter-increment: lst-ctn-kix_2hr0ax18du19-0;
}

.lst-kix_kp4y6vngjbdi-4 > li {
  counter-increment: lst-ctn-kix_kp4y6vngjbdi-4;
}

.lst-kix_3d3nmrtok4kr-4 > li {
  counter-increment: lst-ctn-kix_3d3nmrtok4kr-4;
}

ol.lst-kix_2hr0ax18du19-3.start {
  counter-reset: lst-ctn-kix_2hr0ax18du19-3 0;
}

ol.lst-kix_6uto0bibcuqj-2.start {
  counter-reset: lst-ctn-kix_6uto0bibcuqj-2 0;
}

ol.lst-kix_6uto0bibcuqj-8.start {
  counter-reset: lst-ctn-kix_6uto0bibcuqj-8 0;
}

ol.lst-kix_y75o93thcz3c-2.start {
  counter-reset: lst-ctn-kix_y75o93thcz3c-2 0;
}

ol.lst-kix_y75o93thcz3c-7.start {
  counter-reset: lst-ctn-kix_y75o93thcz3c-7 0;
}

ol.lst-kix_3d3nmrtok4kr-6.start {
  counter-reset: lst-ctn-kix_3d3nmrtok4kr-6 0;
}

.lst-kix_y75o93thcz3c-4 > li {
  counter-increment: lst-ctn-kix_y75o93thcz3c-4;
}

.lst-kix_3d3nmrtok4kr-6 > li {
  counter-increment: lst-ctn-kix_3d3nmrtok4kr-6;
}

.lst-kix_2hr0ax18du19-4 > li {
  counter-increment: lst-ctn-kix_2hr0ax18du19-4;
}

ol.lst-kix_kp4y6vngjbdi-1.start {
  counter-reset: lst-ctn-kix_kp4y6vngjbdi-1 0;
}

.lst-kix_3d3nmrtok4kr-7 > li {
  counter-increment: lst-ctn-kix_3d3nmrtok4kr-7;
}

ol.lst-kix_kp4y6vngjbdi-4.start {
  counter-reset: lst-ctn-kix_kp4y6vngjbdi-4 0;
}

.lst-kix_y75o93thcz3c-3 > li {
  counter-increment: lst-ctn-kix_y75o93thcz3c-3;
}

.lst-kix_6uto0bibcuqj-3 > li {
  counter-increment: lst-ctn-kix_6uto0bibcuqj-3;
}

.lst-kix_i0e5828rhinh-8 > li {
  counter-increment: lst-ctn-kix_i0e5828rhinh-8;
}

.lst-kix_y75o93thcz3c-2 > li {
  counter-increment: lst-ctn-kix_y75o93thcz3c-2;
}

ol.lst-kix_ckip1ox7jmig-2.start {
  counter-reset: lst-ctn-kix_ckip1ox7jmig-2 0;
}

ol.lst-kix_xzyemwvei0e4-6 {
  list-style-type: none;
}

ol.lst-kix_2hr0ax18du19-1.start {
  counter-reset: lst-ctn-kix_2hr0ax18du19-1 0;
}

.lst-kix_ckip1ox7jmig-8 > li {
  counter-increment: lst-ctn-kix_ckip1ox7jmig-8;
}

ol.lst-kix_y75o93thcz3c-0 {
  list-style-type: none;
}

ol.lst-kix_y75o93thcz3c-4.start {
  counter-reset: lst-ctn-kix_y75o93thcz3c-4 0;
}

ol.lst-kix_xzyemwvei0e4-7 {
  list-style-type: none;
}

ol.lst-kix_y75o93thcz3c-1 {
  list-style-type: none;
}

ol.lst-kix_xzyemwvei0e4-8 {
  list-style-type: none;
}

ol.lst-kix_xzyemwvei0e4-2 {
  list-style-type: none;
}

ol.lst-kix_kp4y6vngjbdi-7 {
  list-style-type: none;
}

ol.lst-kix_xzyemwvei0e4-3 {
  list-style-type: none;
}

ol.lst-kix_kp4y6vngjbdi-8 {
  list-style-type: none;
}

ol.lst-kix_xzyemwvei0e4-4 {
  list-style-type: none;
}

ol.lst-kix_xzyemwvei0e4-5 {
  list-style-type: none;
}

.lst-kix_2hr0ax18du19-5 > li {
  counter-increment: lst-ctn-kix_2hr0ax18du19-5;
}

ol.lst-kix_y75o93thcz3c-8 {
  list-style-type: none;
}

ol.lst-kix_xzyemwvei0e4-0 {
  list-style-type: none;
}

ol.lst-kix_y75o93thcz3c-6 {
  list-style-type: none;
}

ol.lst-kix_xzyemwvei0e4-1 {
  list-style-type: none;
}

ol.lst-kix_y75o93thcz3c-7 {
  list-style-type: none;
}

.lst-kix_3d3nmrtok4kr-5 > li {
  counter-increment: lst-ctn-kix_3d3nmrtok4kr-5;
}

ol.lst-kix_y75o93thcz3c-4 {
  list-style-type: none;
}

ol.lst-kix_y75o93thcz3c-5 {
  list-style-type: none;
}

ol.lst-kix_y75o93thcz3c-2 {
  list-style-type: none;
}

ol.lst-kix_y75o93thcz3c-3 {
  list-style-type: none;
}

.lst-kix_kp4y6vngjbdi-2 > li {
  counter-increment: lst-ctn-kix_kp4y6vngjbdi-2;
}

ol.lst-kix_i0e5828rhinh-1.start {
  counter-reset: lst-ctn-kix_i0e5828rhinh-1 0;
}

.lst-kix_fbk3h0ckjtov-0 > li {
  counter-increment: lst-ctn-kix_fbk3h0ckjtov-0;
}

.lst-kix_i0e5828rhinh-2 > li:before {
  content: "" counter(lst-ctn-kix_i0e5828rhinh-2, lower-roman) ". ";
}

ol.lst-kix_3d3nmrtok4kr-8.start {
  counter-reset: lst-ctn-kix_3d3nmrtok4kr-8 0;
}

ol.lst-kix_kp4y6vngjbdi-3 {
  list-style-type: none;
}

.lst-kix_6uto0bibcuqj-2 > li {
  counter-increment: lst-ctn-kix_6uto0bibcuqj-2;
}

ol.lst-kix_kp4y6vngjbdi-4 {
  list-style-type: none;
}

.lst-kix_6uto0bibcuqj-8 > li {
  counter-increment: lst-ctn-kix_6uto0bibcuqj-8;
}

ol.lst-kix_kp4y6vngjbdi-5 {
  list-style-type: none;
}

ol.lst-kix_y75o93thcz3c-5.start {
  counter-reset: lst-ctn-kix_y75o93thcz3c-5 0;
}

.lst-kix_kp4y6vngjbdi-3 > li {
  counter-increment: lst-ctn-kix_kp4y6vngjbdi-3;
}

ol.lst-kix_kp4y6vngjbdi-6 {
  list-style-type: none;
}

ol.lst-kix_kp4y6vngjbdi-0 {
  list-style-type: none;
}

.lst-kix_i0e5828rhinh-0 > li:before {
  content: "" counter(lst-ctn-kix_i0e5828rhinh-0, decimal) ". ";
}

ol.lst-kix_kp4y6vngjbdi-1 {
  list-style-type: none;
}

ol.lst-kix_kp4y6vngjbdi-2 {
  list-style-type: none;
}

.lst-kix_fbk3h0ckjtov-1 > li {
  counter-increment: lst-ctn-kix_fbk3h0ckjtov-1;
}

ol.lst-kix_fbk3h0ckjtov-7.start {
  counter-reset: lst-ctn-kix_fbk3h0ckjtov-7 0;
}

ol.lst-kix_y75o93thcz3c-6.start {
  counter-reset: lst-ctn-kix_y75o93thcz3c-6 0;
}

ol.lst-kix_3d3nmrtok4kr-7.start {
  counter-reset: lst-ctn-kix_3d3nmrtok4kr-7 0;
}

.lst-kix_ckip1ox7jmig-3 > li {
  counter-increment: lst-ctn-kix_ckip1ox7jmig-3;
}

ol.lst-kix_i0e5828rhinh-2.start {
  counter-reset: lst-ctn-kix_i0e5828rhinh-2 0;
}

.lst-kix_fbk3h0ckjtov-7 > li {
  counter-increment: lst-ctn-kix_fbk3h0ckjtov-7;
}

ol.lst-kix_kp4y6vngjbdi-2.start {
  counter-reset: lst-ctn-kix_kp4y6vngjbdi-2 0;
}

.lst-kix_ckip1ox7jmig-1 > li:before {
  content: "" counter(lst-ctn-kix_ckip1ox7jmig-1, lower-latin) ". ";
}

.lst-kix_3d3nmrtok4kr-3 > li:before {
  content: "" counter(lst-ctn-kix_3d3nmrtok4kr-3, decimal) ". ";
}

.lst-kix_xzyemwvei0e4-4 > li {
  counter-increment: lst-ctn-kix_xzyemwvei0e4-4;
}

.lst-kix_3d3nmrtok4kr-1 > li:before {
  content: "" counter(lst-ctn-kix_3d3nmrtok4kr-1, lower-latin) ". ";
}

ol.lst-kix_ckip1ox7jmig-0.start {
  counter-reset: lst-ctn-kix_ckip1ox7jmig-0 0;
}

ol.lst-kix_fbk3h0ckjtov-8.start {
  counter-reset: lst-ctn-kix_fbk3h0ckjtov-8 0;
}

.lst-kix_fbk3h0ckjtov-8 > li:before {
  content: "" counter(lst-ctn-kix_fbk3h0ckjtov-8, lower-roman) ". ";
}

.lst-kix_fbk3h0ckjtov-5 > li:before {
  content: "" counter(lst-ctn-kix_fbk3h0ckjtov-5, lower-roman) ". ";
}

ol.lst-kix_xzyemwvei0e4-3.start {
  counter-reset: lst-ctn-kix_xzyemwvei0e4-3 0;
}

.lst-kix_y75o93thcz3c-0 > li:before {
  content: "" counter(lst-ctn-kix_y75o93thcz3c-0, decimal) ". ";
}

.lst-kix_xzyemwvei0e4-8 > li:before {
  content: "" counter(lst-ctn-kix_xzyemwvei0e4-8, lower-roman) ". ";
}

.lst-kix_fbk3h0ckjtov-2 > li:before {
  content: "" counter(lst-ctn-kix_fbk3h0ckjtov-2, decimal) ". ";
}

.lst-kix_fbk3h0ckjtov-6 > li:before {
  content: "" counter(lst-ctn-kix_fbk3h0ckjtov-6, decimal) ". ";
}

.lst-kix_y75o93thcz3c-3 > li:before {
  content: "" counter(lst-ctn-kix_y75o93thcz3c-3, decimal) ". ";
}

.lst-kix_y75o93thcz3c-4 > li:before {
  content: "" counter(lst-ctn-kix_y75o93thcz3c-4, lower-latin) ". ";
}

.lst-kix_fbk3h0ckjtov-3 > li:before {
  content: "" counter(lst-ctn-kix_fbk3h0ckjtov-3, decimal) ". ";
}

ol.lst-kix_i0e5828rhinh-6.start {
  counter-reset: lst-ctn-kix_i0e5828rhinh-6 0;
}

.lst-kix_fbk3h0ckjtov-4 > li:before {
  content: "" counter(lst-ctn-kix_fbk3h0ckjtov-4, lower-latin) ". ";
}

ol.lst-kix_y75o93thcz3c-0.start {
  counter-reset: lst-ctn-kix_y75o93thcz3c-0 0;
}

.lst-kix_y75o93thcz3c-5 > li:before {
  content: "" counter(lst-ctn-kix_y75o93thcz3c-5, lower-roman) ". ";
}

.lst-kix_xzyemwvei0e4-3 > li:before {
  content: "" counter(lst-ctn-kix_xzyemwvei0e4-3, decimal) ". ";
}

.lst-kix_ckip1ox7jmig-3 > li:before {
  content: "" counter(lst-ctn-kix_ckip1ox7jmig-3, decimal) ". ";
}

.lst-kix_xzyemwvei0e4-5 > li {
  counter-increment: lst-ctn-kix_xzyemwvei0e4-5;
}

.lst-kix_y75o93thcz3c-6 > li:before {
  content: "" counter(lst-ctn-kix_y75o93thcz3c-6, decimal) ". ";
}

.lst-kix_y75o93thcz3c-8 > li:before {
  content: "" counter(lst-ctn-kix_y75o93thcz3c-8, lower-roman) ". ";
}

.lst-kix_ckip1ox7jmig-4 > li:before {
  content: "" counter(lst-ctn-kix_ckip1ox7jmig-4, lower-latin) ". ";
}

.lst-kix_xzyemwvei0e4-4 > li:before {
  content: "" counter(lst-ctn-kix_xzyemwvei0e4-4, lower-latin) ". ";
}

.lst-kix_xzyemwvei0e4-5 > li:before {
  content: "" counter(lst-ctn-kix_xzyemwvei0e4-5, lower-roman) ". ";
}

.lst-kix_kp4y6vngjbdi-7 > li:before {
  content: "" counter(lst-ctn-kix_kp4y6vngjbdi-7, lower-latin) ". ";
}

.lst-kix_y75o93thcz3c-7 > li:before {
  content: "" counter(lst-ctn-kix_y75o93thcz3c-7, lower-latin) ". ";
}

.lst-kix_fbk3h0ckjtov-1 > li:before {
  content: "" counter(lst-ctn-kix_fbk3h0ckjtov-1, decimal) ". ";
}

.lst-kix_ckip1ox7jmig-6 > li:before {
  content: "" counter(lst-ctn-kix_ckip1ox7jmig-6, decimal) ". ";
}

.lst-kix_xzyemwvei0e4-6 > li:before {
  content: "" counter(lst-ctn-kix_xzyemwvei0e4-6, decimal) ". ";
}

.lst-kix_xzyemwvei0e4-7 > li:before {
  content: "" counter(lst-ctn-kix_xzyemwvei0e4-7, lower-latin) ". ";
}

.lst-kix_ckip1ox7jmig-5 > li:before {
  content: "" counter(lst-ctn-kix_ckip1ox7jmig-5, lower-roman) ". ";
}

.lst-kix_ckip1ox7jmig-7 > li:before {
  content: "" counter(lst-ctn-kix_ckip1ox7jmig-7, lower-latin) ". ";
}

ol.lst-kix_2hr0ax18du19-0.start {
  counter-reset: lst-ctn-kix_2hr0ax18du19-0 0;
}

.lst-kix_kp4y6vngjbdi-8 > li:before {
  content: "" counter(lst-ctn-kix_kp4y6vngjbdi-8, lower-roman) ". ";
}

.lst-kix_i0e5828rhinh-2 > li {
  counter-increment: lst-ctn-kix_i0e5828rhinh-2;
}

.lst-kix_fbk3h0ckjtov-0 > li:before {
  content: "" counter(lst-ctn-kix_fbk3h0ckjtov-0, decimal) ". ";
}

ol.lst-kix_i0e5828rhinh-0 {
  list-style-type: none;
}

.lst-kix_2hr0ax18du19-5 > li:before {
  content: "" counter(lst-ctn-kix_2hr0ax18du19-5, lower-roman) ". ";
}

ol.lst-kix_i0e5828rhinh-1 {
  list-style-type: none;
}

ol.lst-kix_i0e5828rhinh-2 {
  list-style-type: none;
}

ol.lst-kix_i0e5828rhinh-3 {
  list-style-type: none;
}

ol.lst-kix_i0e5828rhinh-4 {
  list-style-type: none;
}

.lst-kix_xzyemwvei0e4-3 > li {
  counter-increment: lst-ctn-kix_xzyemwvei0e4-3;
}

.lst-kix_2hr0ax18du19-6 > li:before {
  content: "" counter(lst-ctn-kix_2hr0ax18du19-6, decimal) ". ";
}

ol.lst-kix_i0e5828rhinh-5 {
  list-style-type: none;
}

.lst-kix_ckip1ox7jmig-8 > li:before {
  content: "" counter(lst-ctn-kix_ckip1ox7jmig-8, lower-roman) ". ";
}

ol.lst-kix_i0e5828rhinh-6 {
  list-style-type: none;
}

ol.lst-kix_i0e5828rhinh-7 {
  list-style-type: none;
}

ol.lst-kix_i0e5828rhinh-8 {
  list-style-type: none;
}

.lst-kix_2hr0ax18du19-7 > li:before {
  content: "" counter(lst-ctn-kix_2hr0ax18du19-7, lower-latin) ". ";
}

ol.lst-kix_6uto0bibcuqj-6.start {
  counter-reset: lst-ctn-kix_6uto0bibcuqj-6 0;
}

.lst-kix_2hr0ax18du19-8 > li:before {
  content: "" counter(lst-ctn-kix_2hr0ax18du19-8, lower-roman) ". ";
}

.lst-kix_i0e5828rhinh-8 > li:before {
  content: "" counter(lst-ctn-kix_i0e5828rhinh-8, lower-roman) ". ";
}

.lst-kix_xzyemwvei0e4-2 > li:before {
  content: "" counter(lst-ctn-kix_xzyemwvei0e4-2, lower-roman) ". ";
}

.lst-kix_ckip1ox7jmig-5 > li {
  counter-increment: lst-ctn-kix_ckip1ox7jmig-5;
}

ol.lst-kix_fbk3h0ckjtov-3.start {
  counter-reset: lst-ctn-kix_fbk3h0ckjtov-3 0;
}

.lst-kix_i0e5828rhinh-7 > li:before {
  content: "" counter(lst-ctn-kix_i0e5828rhinh-7, lower-latin) ". ";
}

.lst-kix_kp4y6vngjbdi-5 > li {
  counter-increment: lst-ctn-kix_kp4y6vngjbdi-5;
}

.lst-kix_i0e5828rhinh-0 > li {
  counter-increment: lst-ctn-kix_i0e5828rhinh-0;
}

.lst-kix_xzyemwvei0e4-0 > li:before {
  content: "" counter(lst-ctn-kix_xzyemwvei0e4-0, decimal) ". ";
}

.lst-kix_xzyemwvei0e4-1 > li:before {
  content: "" counter(lst-ctn-kix_xzyemwvei0e4-1, lower-latin) ". ";
}

.lst-kix_i0e5828rhinh-6 > li:before {
  content: "" counter(lst-ctn-kix_i0e5828rhinh-6, decimal) ". ";
}

ol.lst-kix_3d3nmrtok4kr-2.start {
  counter-reset: lst-ctn-kix_3d3nmrtok4kr-2 0;
}

.lst-kix_i0e5828rhinh-3 > li:before {
  content: "" counter(lst-ctn-kix_i0e5828rhinh-3, decimal) ". ";
}

.lst-kix_y75o93thcz3c-8 > li {
  counter-increment: lst-ctn-kix_y75o93thcz3c-8;
}

.lst-kix_i0e5828rhinh-5 > li:before {
  content: "" counter(lst-ctn-kix_i0e5828rhinh-5, lower-roman) ". ";
}

ol.lst-kix_kp4y6vngjbdi-0.start {
  counter-reset: lst-ctn-kix_kp4y6vngjbdi-0 0;
}

ol.lst-kix_i0e5828rhinh-0.start {
  counter-reset: lst-ctn-kix_i0e5828rhinh-0 2;
}

ol.lst-kix_6uto0bibcuqj-0.start {
  counter-reset: lst-ctn-kix_6uto0bibcuqj-0 0;
}

.lst-kix_i0e5828rhinh-4 > li:before {
  content: "" counter(lst-ctn-kix_i0e5828rhinh-4, lower-latin) ". ";
}

ol.lst-kix_fbk3h0ckjtov-8 {
  list-style-type: none;
}

ol.lst-kix_fbk3h0ckjtov-7 {
  list-style-type: none;
}

ol.lst-kix_fbk3h0ckjtov-6 {
  list-style-type: none;
}

ol.lst-kix_fbk3h0ckjtov-5 {
  list-style-type: none;
}

ol.lst-kix_kp4y6vngjbdi-6.start {
  counter-reset: lst-ctn-kix_kp4y6vngjbdi-6 0;
}

ol.lst-kix_fbk3h0ckjtov-4 {
  list-style-type: none;
}

.lst-kix_ckip1ox7jmig-7 > li {
  counter-increment: lst-ctn-kix_ckip1ox7jmig-7;
}

ol.lst-kix_fbk3h0ckjtov-3 {
  list-style-type: none;
}

ol.lst-kix_fbk3h0ckjtov-2 {
  list-style-type: none;
}

.lst-kix_y75o93thcz3c-1 > li {
  counter-increment: lst-ctn-kix_y75o93thcz3c-1;
}

.lst-kix_ckip1ox7jmig-1 > li {
  counter-increment: lst-ctn-kix_ckip1ox7jmig-1;
}

ol.lst-kix_ckip1ox7jmig-3.start {
  counter-reset: lst-ctn-kix_ckip1ox7jmig-3 0;
}

ol.lst-kix_6uto0bibcuqj-7 {
  list-style-type: none;
}

ol.lst-kix_6uto0bibcuqj-8 {
  list-style-type: none;
}

ol.lst-kix_6uto0bibcuqj-3 {
  list-style-type: none;
}

.lst-kix_6uto0bibcuqj-1 > li {
  counter-increment: lst-ctn-kix_6uto0bibcuqj-1;
}

ol.lst-kix_6uto0bibcuqj-4 {
  list-style-type: none;
}

ol.lst-kix_6uto0bibcuqj-5 {
  list-style-type: none;
}

ol.lst-kix_6uto0bibcuqj-6 {
  list-style-type: none;
}

ol.lst-kix_6uto0bibcuqj-0 {
  list-style-type: none;
}

.lst-kix_fbk3h0ckjtov-5 > li {
  counter-increment: lst-ctn-kix_fbk3h0ckjtov-5;
}

ol.lst-kix_6uto0bibcuqj-1 {
  list-style-type: none;
}

ol.lst-kix_6uto0bibcuqj-1.start {
  counter-reset: lst-ctn-kix_6uto0bibcuqj-1 0;
}

ol.lst-kix_6uto0bibcuqj-2 {
  list-style-type: none;
}

ol.lst-kix_fbk3h0ckjtov-4.start {
  counter-reset: lst-ctn-kix_fbk3h0ckjtov-4 0;
}

ol.lst-kix_xzyemwvei0e4-8.start {
  counter-reset: lst-ctn-kix_xzyemwvei0e4-8 0;
}

.lst-kix_kp4y6vngjbdi-1 > li:before {
  content: "" counter(lst-ctn-kix_kp4y6vngjbdi-1, lower-latin) ". ";
}

.lst-kix_kp4y6vngjbdi-2 > li:before {
  content: "" counter(lst-ctn-kix_kp4y6vngjbdi-2, lower-roman) ". ";
}

ol.lst-kix_ckip1ox7jmig-4.start {
  counter-reset: lst-ctn-kix_ckip1ox7jmig-4 0;
}

.lst-kix_6uto0bibcuqj-0 > li:before {
  content: "" counter(lst-ctn-kix_6uto0bibcuqj-0, decimal) ". ";
}

.lst-kix_2hr0ax18du19-4 > li:before {
  content: "" counter(lst-ctn-kix_2hr0ax18du19-4, lower-latin) ". ";
}

ol.lst-kix_kp4y6vngjbdi-5.start {
  counter-reset: lst-ctn-kix_kp4y6vngjbdi-5 0;
}

.lst-kix_kp4y6vngjbdi-7 > li {
  counter-increment: lst-ctn-kix_kp4y6vngjbdi-7;
}

.lst-kix_kp4y6vngjbdi-3 > li:before {
  content: "" counter(lst-ctn-kix_kp4y6vngjbdi-3, decimal) ". ";
}

.lst-kix_6uto0bibcuqj-1 > li:before {
  content: "" counter(lst-ctn-kix_6uto0bibcuqj-1, decimal) ". ";
}

.lst-kix_2hr0ax18du19-3 > li:before {
  content: "" counter(lst-ctn-kix_2hr0ax18du19-3, decimal) ". ";
}

.lst-kix_fbk3h0ckjtov-2 > li {
  counter-increment: lst-ctn-kix_fbk3h0ckjtov-2;
}

.lst-kix_kp4y6vngjbdi-5 > li:before {
  content: "" counter(lst-ctn-kix_kp4y6vngjbdi-5, lower-roman) ". ";
}

.lst-kix_kp4y6vngjbdi-6 > li:before {
  content: "" counter(lst-ctn-kix_kp4y6vngjbdi-6, decimal) ". ";
}

ol.lst-kix_xzyemwvei0e4-2.start {
  counter-reset: lst-ctn-kix_xzyemwvei0e4-2 0;
}

.lst-kix_6uto0bibcuqj-2 > li:before {
  content: "" counter(lst-ctn-kix_6uto0bibcuqj-2, decimal) ". ";
}

.lst-kix_6uto0bibcuqj-4 > li:before {
  content: "" counter(lst-ctn-kix_6uto0bibcuqj-4, lower-latin) ". ";
}

.lst-kix_2hr0ax18du19-0 > li:before {
  content: "" counter(lst-ctn-kix_2hr0ax18du19-0, decimal) ". ";
}

.lst-kix_2hr0ax18du19-2 > li:before {
  content: "" counter(lst-ctn-kix_2hr0ax18du19-2, lower-roman) ". ";
}

.lst-kix_2hr0ax18du19-3 > li {
  counter-increment: lst-ctn-kix_2hr0ax18du19-3;
}

.lst-kix_kp4y6vngjbdi-1 > li {
  counter-increment: lst-ctn-kix_kp4y6vngjbdi-1;
}

.lst-kix_3d3nmrtok4kr-1 > li {
  counter-increment: lst-ctn-kix_3d3nmrtok4kr-1;
}

.lst-kix_kp4y6vngjbdi-4 > li:before {
  content: "" counter(lst-ctn-kix_kp4y6vngjbdi-4, lower-latin) ". ";
}

.lst-kix_2hr0ax18du19-6 > li {
  counter-increment: lst-ctn-kix_2hr0ax18du19-6;
}

.lst-kix_6uto0bibcuqj-3 > li:before {
  content: "" counter(lst-ctn-kix_6uto0bibcuqj-3, decimal) ". ";
}

.lst-kix_2hr0ax18du19-1 > li:before {
  content: "" counter(lst-ctn-kix_2hr0ax18du19-1, lower-latin) ". ";
}

.lst-kix_6uto0bibcuqj-6 > li:before {
  content: "" counter(lst-ctn-kix_6uto0bibcuqj-6, decimal) ". ";
}

.lst-kix_6uto0bibcuqj-8 > li:before {
  content: "" counter(lst-ctn-kix_6uto0bibcuqj-8, lower-roman) ". ";
}

.lst-kix_i0e5828rhinh-7 > li {
  counter-increment: lst-ctn-kix_i0e5828rhinh-7;
}

.lst-kix_i0e5828rhinh-4 > li {
  counter-increment: lst-ctn-kix_i0e5828rhinh-4;
}

ol.lst-kix_i0e5828rhinh-5.start {
  counter-reset: lst-ctn-kix_i0e5828rhinh-5 0;
}

.lst-kix_6uto0bibcuqj-5 > li:before {
  content: "" counter(lst-ctn-kix_6uto0bibcuqj-5, lower-roman) ". ";
}

ol.lst-kix_fbk3h0ckjtov-1 {
  list-style-type: none;
}

ol.lst-kix_fbk3h0ckjtov-0 {
  list-style-type: none;
}

.lst-kix_y75o93thcz3c-2 > li:before {
  content: "" counter(lst-ctn-kix_y75o93thcz3c-2, lower-roman) ". ";
}

.lst-kix_kp4y6vngjbdi-0 > li:before {
  content: "" counter(lst-ctn-kix_kp4y6vngjbdi-0, decimal) ". ";
}

.lst-kix_6uto0bibcuqj-7 > li:before {
  content: "" counter(lst-ctn-kix_6uto0bibcuqj-7, lower-latin) ". ";
}

.lst-kix_y75o93thcz3c-1 > li:before {
  content: "" counter(lst-ctn-kix_y75o93thcz3c-1, decimal) ". ";
}

ol.lst-kix_2hr0ax18du19-4.start {
  counter-reset: lst-ctn-kix_2hr0ax18du19-4 0;
}

.lst-kix_fbk3h0ckjtov-3 > li {
  counter-increment: lst-ctn-kix_fbk3h0ckjtov-3;
}

.lst-kix_kp4y6vngjbdi-0 > li {
  counter-increment: lst-ctn-kix_kp4y6vngjbdi-0;
}

.lst-kix_6uto0bibcuqj-5 > li {
  counter-increment: lst-ctn-kix_6uto0bibcuqj-5;
}

ol.lst-kix_6uto0bibcuqj-7.start {
  counter-reset: lst-ctn-kix_6uto0bibcuqj-7 0;
}

.lst-kix_i0e5828rhinh-6 > li {
  counter-increment: lst-ctn-kix_i0e5828rhinh-6;
}

ol.lst-kix_2hr0ax18du19-7.start {
  counter-reset: lst-ctn-kix_2hr0ax18du19-7 0;
}

ol.lst-kix_fbk3h0ckjtov-2.start {
  counter-reset: lst-ctn-kix_fbk3h0ckjtov-2 0;
}

.lst-kix_fbk3h0ckjtov-4 > li {
  counter-increment: lst-ctn-kix_fbk3h0ckjtov-4;
}

ol.lst-kix_3d3nmrtok4kr-3.start {
  counter-reset: lst-ctn-kix_3d3nmrtok4kr-3 0;
}

ol.lst-kix_6uto0bibcuqj-4.start {
  counter-reset: lst-ctn-kix_6uto0bibcuqj-4 0;
}

.lst-kix_6uto0bibcuqj-4 > li {
  counter-increment: lst-ctn-kix_6uto0bibcuqj-4;
}

.lst-kix_i0e5828rhinh-5 > li {
  counter-increment: lst-ctn-kix_i0e5828rhinh-5;
}

ol.lst-kix_i0e5828rhinh-7.start {
  counter-reset: lst-ctn-kix_i0e5828rhinh-7 0;
}

.lst-kix_xzyemwvei0e4-0 > li {
  counter-increment: lst-ctn-kix_xzyemwvei0e4-0;
}

ol.lst-kix_y75o93thcz3c-1.start {
  counter-reset: lst-ctn-kix_y75o93thcz3c-1 0;
}

.lst-kix_6uto0bibcuqj-6 > li {
  counter-increment: lst-ctn-kix_6uto0bibcuqj-6;
}

.lst-kix_y75o93thcz3c-5 > li {
  counter-increment: lst-ctn-kix_y75o93thcz3c-5;
}

ol.lst-kix_kp4y6vngjbdi-7.start {
  counter-reset: lst-ctn-kix_kp4y6vngjbdi-7 0;
}

.lst-kix_3d3nmrtok4kr-8 > li {
  counter-increment: lst-ctn-kix_3d3nmrtok4kr-8;
}

ol.lst-kix_xzyemwvei0e4-7.start {
  counter-reset: lst-ctn-kix_xzyemwvei0e4-7 0;
}

ol.lst-kix_ckip1ox7jmig-8.start {
  counter-reset: lst-ctn-kix_ckip1ox7jmig-8 0;
}

ol.lst-kix_xzyemwvei0e4-4.start {
  counter-reset: lst-ctn-kix_xzyemwvei0e4-4 0;
}

ol.lst-kix_ckip1ox7jmig-5.start {
  counter-reset: lst-ctn-kix_ckip1ox7jmig-5 0;
}

.lst-kix_2hr0ax18du19-2 > li {
  counter-increment: lst-ctn-kix_2hr0ax18du19-2;
}

ol.lst-kix_6uto0bibcuqj-5.start {
  counter-reset: lst-ctn-kix_6uto0bibcuqj-5 0;
}

.lst-kix_3d3nmrtok4kr-3 > li {
  counter-increment: lst-ctn-kix_3d3nmrtok4kr-3;
}

ol.lst-kix_3d3nmrtok4kr-1.start {
  counter-reset: lst-ctn-kix_3d3nmrtok4kr-1 0;
}

ol.lst-kix_fbk3h0ckjtov-1.start {
  counter-reset: lst-ctn-kix_fbk3h0ckjtov-1 0;
}

.lst-kix_2hr0ax18du19-8 > li {
  counter-increment: lst-ctn-kix_2hr0ax18du19-8;
}

.lst-kix_xzyemwvei0e4-1 > li {
  counter-increment: lst-ctn-kix_xzyemwvei0e4-1;
}

.lst-kix_i0e5828rhinh-1 > li:before {
  content: "" counter(lst-ctn-kix_i0e5828rhinh-1, lower-latin) ". ";
}

ol.lst-kix_ckip1ox7jmig-7.start {
  counter-reset: lst-ctn-kix_ckip1ox7jmig-7 0;
}

.lst-kix_2hr0ax18du19-1 > li {
  counter-increment: lst-ctn-kix_2hr0ax18du19-1;
}

.lst-kix_y75o93thcz3c-6 > li {
  counter-increment: lst-ctn-kix_y75o93thcz3c-6;
}

.lst-kix_y75o93thcz3c-0 > li {
  counter-increment: lst-ctn-kix_y75o93thcz3c-0;
}

ol.lst-kix_xzyemwvei0e4-5.start {
  counter-reset: lst-ctn-kix_xzyemwvei0e4-5 0;
}

.lst-kix_2hr0ax18du19-7 > li {
  counter-increment: lst-ctn-kix_2hr0ax18du19-7;
}

ol.lst-kix_2hr0ax18du19-5.start {
  counter-reset: lst-ctn-kix_2hr0ax18du19-5 0;
}

.lst-kix_ckip1ox7jmig-0 > li {
  counter-increment: lst-ctn-kix_ckip1ox7jmig-0;
}

ol.lst-kix_ckip1ox7jmig-6.start {
  counter-reset: lst-ctn-kix_ckip1ox7jmig-6 0;
}

.lst-kix_3d3nmrtok4kr-4 > li:before {
  content: "" counter(lst-ctn-kix_3d3nmrtok4kr-4, lower-latin) ". ";
}

.lst-kix_ckip1ox7jmig-6 > li {
  counter-increment: lst-ctn-kix_ckip1ox7jmig-6;
}

li.li-bullet-0:before {
  margin-left: -18px;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

ol.lst-kix_3d3nmrtok4kr-0.start {
  counter-reset: lst-ctn-kix_3d3nmrtok4kr-0 0;
}

ol.lst-kix_xzyemwvei0e4-6.start {
  counter-reset: lst-ctn-kix_xzyemwvei0e4-6 0;
}

.lst-kix_ckip1ox7jmig-2 > li:before {
  content: "" counter(lst-ctn-kix_ckip1ox7jmig-2, lower-roman) ". ";
}

.lst-kix_ckip1ox7jmig-0 > li:before {
  content: "" counter(lst-ctn-kix_ckip1ox7jmig-0, decimal) ". ";
}

ol.lst-kix_2hr0ax18du19-6.start {
  counter-reset: lst-ctn-kix_2hr0ax18du19-6 0;
}

ol.lst-kix_kp4y6vngjbdi-8.start {
  counter-reset: lst-ctn-kix_kp4y6vngjbdi-8 0;
}

.lst-kix_3d3nmrtok4kr-2 > li:before {
  content: "" counter(lst-ctn-kix_3d3nmrtok4kr-2, lower-roman) ". ";
}

ol.lst-kix_i0e5828rhinh-8.start {
  counter-reset: lst-ctn-kix_i0e5828rhinh-8 0;
}

.lst-kix_xzyemwvei0e4-7 > li {
  counter-increment: lst-ctn-kix_xzyemwvei0e4-7;
}

.lst-kix_fbk3h0ckjtov-7 > li:before {
  content: "" counter(lst-ctn-kix_fbk3h0ckjtov-7, lower-latin) ". ";
}

.lst-kix_3d3nmrtok4kr-0 > li:before {
  content: "" counter(lst-ctn-kix_3d3nmrtok4kr-0, decimal) ". ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c2 {
  margin-left: 144px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c4 {
  margin-left: 36px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c5 {
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;

  font-style: normal;
}

.c16 {
  padding-top: 16px;
  padding-bottom: 4px;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c0 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;

  font-style: normal;
}

.c17 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;

  font-style: normal;
}

.c19 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;

  font-style: normal;
}

.c6 {
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c8 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}

.c15 {
  background-color: #ffffff;
  max-width: 468px;
  padding: 72pt 72pt 72pt 72pt;
}

.c3 {
  text-decoration: inherit;
}

.c11 {
  margin-left: 108px;
  padding-left: 0pt;
}

.c10 {
  margin-left: 72px;
  padding-left: 0pt;
}

.c1 {
  padding: 0;
  margin: 0;
}

.c18 {
  margin-left: 72pt;
}

.c12 {
  margin-left: 36pt;
}

.c13 {
  font-weight: 700;
}

.c7 {
  height: 11pt;
}

.title {
  padding-top: 0px;
  font-size: 26pt;
  padding-bottom: 3px;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 0px;
  font-size: 15pt;
  padding-bottom: 16px;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  font-size: 11pt;
}

h1 {
  padding-top: 20px;
  font-size: 20pt;
  padding-bottom: 6px;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 18px;
  font-size: 16pt;
  padding-bottom: 6px;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 16px;
  font-size: 14pt;
  padding-bottom: 4px;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 14px;
  font-size: 12pt;
  padding-bottom: 4px;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 12px;
  font-size: 11pt;
  padding-bottom: 4px;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 12px;
  font-size: 11pt;
  padding-bottom: 4px;

  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
